import request, { MarkcoinRequest } from '@/plugins/request'
import {
  ReciveListReq,
  ReciveListResp,
  ReciveEnabledReq,
  PaymentEnabledReq,
  EditJudgeReq,
  EditJudgeResq,
  AreaListReq,
  PaymentGetReq,
  PaymentRemoveReq,
  AvailableAreaItem,
} from '@/typings/api/assets/fiat-payment'

/* -----------       收付款管理       ----------- */

/**
 * c2c 中心 - 付款方式列表
 * https://yapi.nbttfc365.com/project/73/interface/api/5291
 */
export const fetchC2CPaymentList: MarkcoinRequest = () => {
  return request({
    path: `/v1/pay/payment/list`,
    method: 'GET',
  })
}

/**
 * c2c 中心 - 收款方式列表
 * https://yapi.nbttfc365.com/project/73/interface/api/5300
 */
export const fetchC2CPaymentReciveList: MarkcoinRequest<ReciveListReq, ReciveListResp> = params => {
  return request({
    path: `/v1/pay/payment/reciveList`,
    method: 'GET',
    params,
  })
}

/**
 * c2c 中心 - 收款方式开关
 * https://yapi.nbttfc365.com/project/73/interface/api/5309
 */
export const fetchC2CPaymentReciveEnabled: MarkcoinRequest<ReciveEnabledReq, boolean> = data => {
  return request({
    path: `/v1/pay/payment/recive/enabled`,
    method: 'POST',
    data,
  })
}

/**
 * c2c 中心 - 付款方式开关
 * https://yapi.nbttfc365.com/project/73/interface/api/5318
 */
export const fetchC2CPaymentEnabled: MarkcoinRequest<PaymentEnabledReq, boolean> = data => {
  return request({
    path: `/v1/pay/payment/enabled`,
    method: 'POST',
    data,
  })
}

/**
 * c2c 中心 - 收款方式修改前置判断
 * https://yapi.nbttfc365.com/project/73/interface/api/5336
 */
export const fetchC2CPaymentReciveEditJudge: MarkcoinRequest<EditJudgeReq, EditJudgeResq> = data => {
  return request({
    path: `/v1/pay/payment/recive/edit/judge`,
    method: 'POST',
    data,
  })
}

/**
 * c2c 中心 - （动态）增加收款方式
 * https://yapi.nbttfc365.com/project/73/interface/api/4627
 */
export const fetchC2CNewPaymentAdd: MarkcoinRequest = options => {
  return request({
    path: `/v2/pay/payment/add`,
    method: 'POST',
    data: {
      legalCurrencyId: options.legalCurrencyId, // 法币 IDs(多个逗号隔开)
      paymentTypeCd: options.paymentTypeCd, // 支付类型 (数据字典：payment_type_cd)
      // name: options.name, // 姓名
      // account: options.account, // 账号
      // bankOfDeposit: options.bankOfDeposit, // 开户行
      // qrCodeAddr: options.qrCodeAddr, // 二维码 (图片资源地址)
      type: options.type, // 收付类型 (IN 收款 OUT 付款)
      ...options,
    },
  })
}

/**
 * c2c 中心 - 获取可交易的区域列表
 * https://yapi.nbttfc365.com/project/73/interface/api/4571
 */
export const fetchC2CAreaList: MarkcoinRequest<AreaListReq, AvailableAreaItem[]> = params => {
  return request({
    path: `/v1/pay/payment/area/list`,
    method: 'GET',
    params,
  })
}

/**
 * c2c 中心 - （动态）修改收款方式
 * https://yapi.nbttfc365.com/project/73/interface/api/4639
 */
export const fetchC2NewCPaymentModify: MarkcoinRequest = options => {
  return request({
    path: `/v2/pay/payment/modify`,
    method: 'POST',
    data: {
      ...options,
      id: options.id, // 支付方式 ID
      legalCurrencyId: options.legalCurrencyId,
      paymentTypeCd: options.paymentTypeCd, // 支付类型 (数据字典：payment_type_cd)
      // name: options.name, // 姓名
      // account: options.account, // 账号
      // bankOfDeposit: options.bankOfDeposit, // 开户行
      // qrCodeAddr: options.qrCodeAddr, // 二维码 (图片资源地址)
    },
  })
}

/**
 * c2c 中心 - 获取收付方式详情
 * https://yapi.nbttfc365.com/project/73/interface/api/4635
 */
export const fetchC2CPaymentGet: MarkcoinRequest<PaymentGetReq> = params => {
  return request({
    path: `/v1/pay/payment/get`,
    method: 'GET',
    params,
  })
}

/**
 * c2c 中心 - 删除收款方式
 * https://yapi.nbttfc365.com/project/73/interface/api/4643
 */
export const fetchC2CPaymentRemove: MarkcoinRequest<PaymentRemoveReq, { isSuccess: boolean }> = data => {
  return request({
    path: `/v1/pay/payment/remove`,
    method: 'POST',
    data,
  })
}

/* -----------       收付款管理 END       ----------- */
